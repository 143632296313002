import React, { useState } from 'react';
import { Collapse, Input, Steps, Image } from 'antd';
import './docs.style.less';
import { Section_1_1, Section_1_2, Section_1_3_1, Section_1_3_2, Section_1_5, Section_1_6, Section_2_1, Section_2_2, Section_2_3, Section_2_4, Section_2_5, Section_2_6, Section_2_7, Section_3_1, Section_4_1, Section_4_2, Section_5_1, Section_5_2, Section_6_1, Section_6_2 } from './docs.images';

const { Panel } = Collapse;
const { Step } = Steps;

const onChange = (key: string | string[]) => {
  console.log(key);
};

const RentDocsPage = () => {
  const [q, setQ] = useState('');

  const data: any = [
    {
      title: 'Owners -- how to create new owner',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Property Owner" sidebar from the left side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_1_1} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Create New Owner" button from the right side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={1} alt='' src={Section_1_2} />
                </div>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={2} alt='' src={Section_1_3_1} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Owners -- Pay Owner -- how to pay owner',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Property Owner" sidebar from the left side and select a particular owner from the table</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_1_5} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div className='flex flex-column'>
                <span className='mt-2 font-bold text-base'>1. On "Pay Owner" clicked you will get a Payment Modal with list of all the properties for that owner and his respected invoices.</span>
                <span className='mt-2 font-bold text-base'>2. Choose the invoices to be paid and you will see the final breakup of how much you will pay owner.</span>
                <span className='mt-2 font-bold text-base'>3. Once you have finalized that, click on the "Pay" button, it will download the recepit for you and the owner gets those recepit through mail.</span>
                <span className='mt-2 font-bold text-base'>Note: You can upload recipts here too.</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={1} alt='' src={Section_1_6} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Property -- how to create new property',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Property" sidebar from the left side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_2_1} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Create New Property" button from the right side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={1} alt='' src={Section_2_2} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 3'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>From "Property Owner" dropdown choose the created Owner</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={1} alt='' src={Section_2_3} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Property -- Book Property -- how to book a property',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Property" sidebar from the left side and select a particular "vacant property" from the table</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_2_4} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div className='flex flex-column'>
                <span className='mt-2 font-bold text-base'>1. On "Book Property" click you will get a Booking Modal, fill the details and click "SAVE".</span>
                <span className='mt-2 font-bold text-base'>2. Once Booking success, the property is in the booked section.</span>
                <span className='mt-2 font-bold text-base'>Note: You can track the booking in the booked property section.</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={1} alt='' src={Section_2_5} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Property -- Cancel Booking -- how to cancel a booking',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Property" sidebar from the left side and select a particular "booked property" from the table</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_2_6} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div className='flex flex-column'>
                <span className='mt-2 font-bold text-base'>1. On "Cancel Booking" click, the property will be removed from the booked section and it will be vacant.</span>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Property -- Refund Booking -- how to refund a booking',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Property" sidebar from the left side and select a particular "booked property" from the table</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_2_7} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div className='flex flex-column'>
                <span className='mt-2 font-bold text-base'>On Click you will get a modal to fill refunded details and click on "Refund" button.</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={1} alt='' src={Section_2_5} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Tenants -- how to create new tenant',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Tenant" sidebar from the left side and click "Create New Property" button from the right side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_3_1} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Save" in the Add Tenant Screen</span>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Contract -- how to create new contract',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Contract" sidebar from the left side and click "Create New Contract" button from the right side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_4_1} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div className='flex flex-column'>
                <span className='mt-2 font-bold text-base'>Choose Property and Tenant from the list and click "Save" in the Add Contract Screen</span>
                <span className='mt-2 font-bold text-base'>Note: In here the link is established between Property and Tenant</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_4_2} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Commission -- how to create new commission',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Commission" sidebar from the left side and click "Create New Commission" button from the right side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_5_1} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div className='flex flex-column'>
                <span className='mt-2 font-bold text-base'>Choose Owner / Property / Contract from the list and click "Save" in the Add Commission Screen</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_5_2} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
    {
      title: 'Reminder -- how to create new reminder',
      render: () => (
        <Steps direction='vertical'>
          <Step
            status={'finish'}
            title='Step 1'
            description={
              <div>
                <span className='mt-2 font-bold text-base'>Click "Reminder" sidebar from the left side and click "Create New Reminder" button from the right side</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_6_1} />
                </div>
              </div>
            }
          />
          <Step
            status={'finish'}
            title='Step 2'
            description={
              <div className='flex flex-column'>
                <span className='mt-2 font-bold text-base'>Add Reminder details and click "Save" in the Add Reminder Screen.</span>
                <span className='mt-2 font-bold text-base'>Note: You will get the reminder notification in the set "Reminder Date"</span>
                <div className='mt-2'>
                  <Image preview={false} style={{ width: '100%', height: '600px' }} key={0} alt='' src={Section_6_2} />
                </div>
              </div>
            }
          />
        </Steps>
      ),
    },
  ];

  const handleSearchChange = (e: any) => {
    setQ(e.target.value);
  };

  return (
    <React.Fragment>
      <div className='bg-white w-full h-full'>
        <div className='pt-5 flex justify-content-center align-items-center'>
          <div style={{ maxWidth: '1300px' }} className=' bg-white border-round w-full px-5'>
            <div className='mt-2 mb-4 flex justify-content-center align-items-center flex-column'>
              <div className='font-bold text-xl mb-2'>Search your querry</div>
              <Input onChange={handleSearchChange} className='input-container' placeholder='Search' />
            </div>
            <Collapse className='ant-tag-geekblue' defaultActiveKey={['']} onChange={onChange}>
              {data
                ?.filter((values: any) => values?.title && values?.title.toLowerCase().indexOf(q.toLowerCase()) >= 0)
                ?.map((res: any, index: number) => (
                  <Panel
                    header={
                      <div className='text-xl' style={{ color: '#1d39c4' }}>
                        {res?.title}
                      </div>
                    }
                    key={index}>
                    <div className='p-4'>{res?.render()}</div>
                  </Panel>
                ))}
            </Collapse>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RentDocsPage;
